// @LATERDO
// - put in localStorage: categories, guides, organizations, counties, municipalities

import {
  feature as turfFeature,
  lineString as turfLineString,
} from "@turf/helpers"
import { orderBy, uniqWith } from "lodash"
import queryString from "query-string"

import embedParams from "../config/embedParams"
import generateId from "./generateId"
import jsonToHtml from "./jsonToHtml"

// helpers

const valueByLanguage = (object, fallbackLanguage = `sv`) => {
  return object?.[embedParams.language] || object?.[fallbackLanguage] || null
}

const isSeasonalImagePublished = ({ attributes }) => {
  if (attributes.published_in_summer && attributes.published_in_winter)
    return true

  if (!attributes.published_in_summer && !attributes.published_in_winter)
    return false

  const currentMonth = new Date().getMonth() + 1

  if (attributes.published_in_summer && currentMonth >= 3 && currentMonth <= 11)
    return true

  if (attributes.published_in_winter && currentMonth >= 12 && currentMonth <= 2)
    return true

  return false
}

const formatTrafficStop = (stop) => ({
  id: String(stop.id),
  name: valueByLanguage(stop.attributes.name),
  municipalityId: String(stop.attributes.municipality_id),
  type: stop.attributes.stop_type,
  sourceId: stop.attributes.source_uid,
  lat: stop.attributes.marker_point.vertices[0],
  lng: stop.attributes.marker_point.vertices[1],
})

// api calls

const fetchApi = ({
  query, // TODO: rename to `path`
  url,
  method = `GET`,
  body = null, // {}
  params = null, // {}
  userToken = false,
  jsonResponse = true,
  signal,
}) => {
  const headers = {
    Pragma: `x-hw-cache-all`,
    Accept: `application/vnd.api+json`,
    "Cache-Control": `max-age=0`,
    "Content-Type": `application/vnd.api+json`,
    "X-Naturkartan-API-Key": process.env.REACT_APP_API_KEY,
  }

  if (userToken && embedParams.userToken)
    headers.Authorization = `Bearer ${embedParams.userToken}`

  params = params ? `?${queryString.stringify(params)}` : ``

  return window
    .fetch(query ? `${embedParams.apiBase}${query}${params}` : url, {
      method,
      headers,
      body: body ? JSON.stringify(body) : null,
      redirect: `follow`,
      signal,
    })
    .then((response) => {
      if (!response.ok) throw Error(response.statusText)
      return response
    })
    .then((response) => (jsonResponse ? response.json() : response))
    .catch((error) => {
      throw Error(error)
    })
}

const fetchPaginatedApi = (params) => {
  return new Promise((resolve, reject) => {
    const pages = []

    const fetch = (params) => {
      return new Promise((resolve, reject) => {
        return fetchApi(params)
          .then((page) => {
            pages.push(page)

            if (
              page.links &&
              page.links.next &&
              page.links.next != params.query
            )
              return resolve(fetch({ ...params, query: page.links.next }))

            return resolve(true)
          })
          .catch(reject)
      })
    }

    return fetch(params)
      .then(() => resolve(pages))
      .catch(reject)
  })
}

const fetchApiCategories = () => {
  return fetchPaginatedApi({ query: `/v3/categories` }).then((pages) => {
    const categories = []

    pages.forEach((page) =>
      page.data.forEach((c) =>
        categories.push({
          id: String(c.id),
          slug: c.attributes.slug,
          icon: c.attributes.icon,
          label:
            embedParams.categoryNames?.[embedParams.language]?.[c.id] ||
            valueByLanguage(c.attributes.name), // @TODO: rename to `name` or `title`
          aliases:
            c.attributes.aliases && c.attributes.aliases[embedParams.language],
          // searchable: c.attributes.legacy_type != `animal`,
          searchable: true,
          legacyType: c.attributes.legacy_type,
        })
      )
    )

    return orderBy(categories, [`label`], [`asc`])
  })
}

const fetchApiGuides = () => {
  return fetchPaginatedApi({ query: `/v3/guides` }).then((pages) => {
    const guides = []

    pages.forEach((page) =>
      page.data.forEach((g) =>
        guides.push({
          id: String(g.id),
          slug: g.attributes.slug,
          hidden: g.attributes.hidden,
          name: valueByLanguage(g.attributes.name),
        })
      )
    )

    return orderBy(guides, [`name`], [`asc`])
  })
}

const fetchApiOrganization = ({ id }) => {
  return fetchApi({ query: `/v3/organizations/${id}` })
    .then((o) => ({
      id: String(o.data.id),
      name: valueByLanguage(o.data.attributes.name),
      trailStatusReportEnabled: o.data.attributes.trail_status_report_enabled,
    }))
    .catch(() => null)
}

const fetchApiOrganizations = () => {
  return fetchPaginatedApi({ query: `/v3/organizations` }).then((pages) => {
    const organizations = []

    pages.forEach((page) =>
      page.data.forEach((o) =>
        organizations.push({
          id: String(o.id),
          name: valueByLanguage(o.attributes.name),
        })
      )
    )

    return orderBy(organizations, [`name`], [`asc`])
  })
}

const fetchApiCounties = () => {
  return fetchPaginatedApi({ query: `/v3/counties` }).then((pages) => {
    const counties = []

    pages.forEach((page) =>
      page.data.forEach((c) =>
        counties.push({
          id: String(c.id),
          name: valueByLanguage(c.attributes.name),
          // lat: c.attributes.lat,
          // lng: c.attributes.lon,
        })
      )
    )

    return counties
  })
}

const fetchApiMunicipalities = () => {
  return fetchPaginatedApi({ query: `/v3/municipalities` }).then((pages) => {
    const municipalities = []

    pages.forEach((page) =>
      page.data.forEach((m) =>
        municipalities.push({
          id: String(m.id),
          name: valueByLanguage(m.attributes.name),
          // lat: m.attributes.lat,
          // lng: m.attributes.lon,
        })
      )
    )

    return municipalities
  })
}

const fetchApiSiteShapes = ({ id }) => {
  return fetchApi({ query: `/v3.2/sites/${id}` })
    .then((site) => {
      const s = site.data.attributes

      const features = [
        turfFeature(
          {
            type: s.shapes.type,
            coordinates: s.shapes.coordinates,
          },
          {
            parent: id,
            color: s.custom_color || undefined,
          },
          {
            id: String(generateId()),
          }
        ),
      ]

      if (s.surfaces.length) {
        s.surfaces.forEach((surface) => {
          const surfaceCoordinates = s.shapes.coordinates[0].slice(
            surface.from > surface.to ? surface.to : surface.from,
            (surface.from > surface.to ? surface.from : surface.to) + 1
          )

          if (surfaceCoordinates.length) {
            features.push(
              turfLineString(
                surfaceCoordinates,
                { surface: `yes`, type: surface.type, parent: id },
                { id: String(generateId()) }
              )
            )
          }
        })
      }

      return features
    })
    .catch(() => null)
}

const fetchApiSiteElevations = ({ id }) => {
  return fetchApi({ query: `/v3.2/sites/${id}/relationships/elevations` }).then(
    (elevations) => elevations.data.map((n) => n.attributes.elevation)
  )
}

const fetchApiSiteInfo = ({ id }) => {
  const basePromise = fetchApi({ query: `/v3.2/sites/${id}` })
    .then((site) => {
      const s = site.data.attributes

      const openingHours = s.opening_hours?.length ? s.opening_hours : null
      if (openingHours?.length) openingHours.push(openingHours.shift()) // relocates sunday from first to last position

      return {
        description: jsonToHtml(valueByLanguage(s.description, `en`)),
        current: jsonToHtml(valueByLanguage(s.current, `en`)),
        directions: jsonToHtml(valueByLanguage(s.directions, `en`)),
        facts: s.show_facts && jsonToHtml(valueByLanguage(s.facts, `en`)),
        regulations:
          s.show_regulations &&
          jsonToHtml(valueByLanguage(s.regulations, `en`)),
        audioDescription: valueByLanguage(s.audio_description, `en`),
        audioUrl: s.audio_url,
        postalAddress: s.postal_address,
        postalCity: s.postal_city,
        postalCode: s.postal_code,
        postalCountry: s.postal_country,
        openingHours: openingHours,
        difficulty: s.difficulty,
        time: s.time,
        telephoneNumber: s.telephone_number,
        emailAddress: s.email_address,
      }
    })
    .catch(() => {})

  const linksPromise = fetchApi({
    query: `/v3/sites/${id}/relationships/links`,
  })
    .then((links) =>
      links.data
        .filter(
          (l) =>
            l.attributes.url &&
            l.attributes.locale == embedParams.language &&
            ![`canonical`].includes(l.attributes.link_type)
        )
        .map((l) => ({
          id: l.id,
          type: l.attributes.link_type,
          title: l.attributes.text,
          url: l.attributes.url,
        }))
    )
    .catch(() => [])

  const imagesPromise = fetchApi({
    query: `/v3/sites/${id}/relationships/images`,
  })
    .then((images) =>
      orderBy(images.data, (i) => i.attributes.position, [`asc`])
        .filter((i) => !!i.attributes.url && isSeasonalImagePublished(i))
        .map((i) => ({
          id: i.id,
          url: i.attributes.url,
          mediaType: i.attributes.media_type,
          copyright: i.attributes.copyright,
          altText: valueByLanguage(i.attributes.alt_text),
        }))
    )
    .catch(() => [])

  return Promise.all([basePromise, linksPromise, imagesPromise]).then(
    ([base, links, images]) => ({
      ...base,
      links,
      images,
    })
  )
}

const fetchApiSiteNeighbours = ({ id }) => {
  return fetchApi({ query: `/v3/sites/${id}/relationships/neighbours` })
    .then((neighbours) =>
      neighbours.data.map((n) => ({
        id: String(n.id),
      }))
    )
    .catch(() => [])
}

const fetchApiTrip = ({ id }) => {
  const basePromise = fetchApi({ query: `/v3.1/trips/${id}` })
    .then((trip) => {
      const { attributes, relationships } = trip.data

      return {
        id: String(id),
        slug: attributes.slug,
        name: attributes.name,
        description: jsonToHtml(valueByLanguage(attributes.description, `en`)),
        facts: jsonToHtml(valueByLanguage(attributes.facts, `en`)),
        image: attributes.cover_cloudinary_url,
        date: attributes.created_at,
        userId: relationships.user.data.id,
        guideId: (relationships.guide.data || {}).id,
      }
    })
    .catch(() => null)

  const itemsPromise = fetchApi({
    query: `/v3.2/trips/${id}/relationships/items`,
  })
    .then((items) =>
      items.data.map((i) => ({
        id: String(i.id),
        description: jsonToHtml(
          valueByLanguage(i.attributes.description, `en`)
        ),
        position: i.attributes.position,
        siteId: String(i.relationships.site.data.id),
      }))
    )
    .catch(() => [])

  return Promise.all([basePromise, itemsPromise]).then(([base, items]) => {
    if (!base) return null

    return fetchApiUser({ id: base.userId })
      .then((user) => ({
        ...base,
        items,
        user,
      }))
      .catch(() => ({
        ...base,
        items,
        user: null,
      }))
  })
}

const fetchApiList = ({ id }) => {
  const basePromise = fetchApi({ query: `/v3.1/lists/${id}` })
    .then((list) => {
      const { attributes, relationships } = list.data

      return {
        id: String(id),
        slug: attributes.slug,
        name: attributes.name,
        description: jsonToHtml(valueByLanguage(attributes.description, `en`)),
        image: attributes.cover_cloudinary_url,
        date: attributes.created_at,
        userId: relationships.user.data.id,
        guideId: (relationships.guide.data || {}).id,
      }
    })
    .catch(() => null)

  const itemsPromise = fetchApi({
    query: `/v3.1/lists/${id}/relationships/listings`,
  })
    .then((items) =>
      items.data.map((i) => ({
        id: String(i.id),
        description: jsonToHtml(
          valueByLanguage(i.attributes.description, `en`)
        ),
        position: i.attributes.position,
        siteId: String(i.relationships.site.data.id),
      }))
    )
    .catch(() => [])

  return Promise.all([basePromise, itemsPromise]).then(([base, items]) => {
    if (!base) return null

    return fetchApiUser({ id: base.userId })
      .then((user) => ({
        ...base,
        items,
        user,
      }))
      .catch(() => ({
        ...base,
        items,
        user: null,
      }))
  })
}

const fetchApiUser = ({ id }) => {
  return fetchApi({ query: `/v3/users/${id}` })
    .then((u) => ({
      id: String(u.data.id),
      name: u.data.attributes.name,
      username: u.data.attributes.username,
      image: u.data.attributes.portrait_image_url,
    }))
    .catch(() => null)
}

const fetchApiUserVisits = ({ id }) => {
  return fetchApi({ query: `/v3/users/${id}/relationships/visits` })
    .then((visits) =>
      visits.data
        .map(
          (v) =>
            v.relationships.site && {
              id: String(v.id),
              siteId: String(v.relationships.site.data.id),
            }
        )
        .filter(Boolean)
    )
    .catch(() => [])
}

const fetchApiUserWishes = ({ id }) => {
  return fetchApi({
    query: `/v3/users/${id}/relationships/wishes`,
    userToken: true,
  }).then((wishes) =>
    wishes.data
      .map(
        (w) =>
          w.relationships.site && {
            id: String(w.id),
            siteId: String(w.relationships.site.data.id),
          }
      )
      .filter(Boolean)
  )
}

const fetchApiUserWatches = ({ id }) => {
  return fetchApi({
    query: `/v3/users/${id}/relationships/watches`,
    userToken: true,
  }).then((watches) =>
    watches.data
      .map(
        (w) =>
          w.relationships.site && {
            id: String(w.id),
            siteId: String(w.relationships.site.data.id),
          }
      )
      .filter(Boolean)
  )
}

const fetchApiUserLists = ({ id }) => {
  return fetchApi({
    query: `/v3/users/${id}/relationships/lists`,
    userToken: true,
  }).then((lists) =>
    orderBy(lists.data, (l) => (l.attributes.name || ``).toLowerCase(), [
      `asc`,
    ]).map((l) => ({
      id: String(l.id),
      title: l.attributes.name,
      slug: l.attributes.slug,
      hidden: l.attributes.hidden,
      sites:
        lists.included
          ?.filter(
            (i) => i.relationships.site && i.relationships.list.data.id == l.id
          )
          ?.map((i) => String(i.relationships.site.data.id)) || [],
    }))
  )
}

const fetchApiUserTrips = ({ id }) => {
  return fetchApi({
    query: `/v3/users/${id}/relationships/trips`,
    userToken: true,
  }).then((trips) =>
    orderBy(trips.data, (t) => (t.attributes.name || ``).toLowerCase(), [
      `asc`,
    ]).map((l) => ({
      id: String(l.id),
      title: l.attributes.name,
      slug: l.attributes.slug,
      hidden: l.attributes.hidden,
      sites:
        trips.included
          ?.filter(
            (i) => i.relationships.site && i.relationships.trip.data.id == l.id
          )
          ?.map((i) => String(i.relationships.site.data.id)) || [],
    }))
  )
}

const fetchApiTrafficStops = ({ query, lat, lng }, { signal } = {}) => {
  let params = {}

  if (query) params[`filter[query]`] = query

  if (lat && lng)
    params = {
      ...params,
      "filter[latitude]": lat,
      "filter[longitude]": lng,
      "filter[radius]": 10,
      sort: `marker_point`,
    }

  return fetchApi({
    query: `/v3.2/traffic/stops`,
    params,
    signal,
  }).then((stops) =>
    uniqWith(
      stops.data.map((s) => formatTrafficStop(s)),
      (a, b) => a.sourceId == b.sourceId && a.name == b.name
    )
  )
}

const fetchApiTrafficStop = ({ id }, { signal } = {}) => {
  return fetchApi({
    query: `/v3.2/traffic/stops/${id}`,
    signal,
  }).then((stop) => formatTrafficStop(stop.data))
}

const fetchApiTrafficTrips = ({ from, to }, { signal } = {}) => {
  return fetchApi({
    query: `/v3.2/traffic/trips`,
    params: {
      "filter[from]": from,
      "filter[to]": to,
    },
    signal,
  }).then((trips) =>
    trips.data.map((t) => ({
      id: String(generateId()),
      startDate: t.attributes.origin.datetime,
      finishDate: t.attributes.destination.datetime,
      legs:
        t.attributes.legs?.map((leg) => ({
          id: String(generateId()),
          name: leg.name,
          type: leg.type,
          direction: leg.direction,
          duration: leg.duration,
          startName: leg.origin.name,
          finishName: leg.destination.name,
          startDate: leg.origin.datetime,
          finishDate: leg.destination.datetime,
          displayNumber: leg.product[0].displayNumber,
          categoryCode: leg.product[0].catCode,
          operators: leg.product.map((p) => p.operator).filter(Boolean),
          stops: leg.stops.map((stop) => ({
            name: stop.name,
            lat: stop.lat,
            lng: stop.lon,
          })),
        })) || [],
    }))
  )
}

const postApiWatches = (id) => {
  return fetchApi({
    query: `/v3/watches`,
    method: `POST`,
    body: {
      data: {
        type: `watches`,
        attributes: {},
        relationships: {
          site: {
            data: {
              type: `sites`,
              id: id,
            },
          },
        },
      },
    },
    userToken: true,
  }).then((watch) => ({
    id: String(watch.data.id),
  }))
}

const postApiWishes = (id) => {
  return fetchApi({
    query: `/v3/wishes`,
    method: `POST`,
    body: {
      data: {
        type: `wishes`,
        attributes: {},
        relationships: {
          site: {
            data: {
              type: `sites`,
              id: id,
            },
          },
        },
      },
    },
    userToken: true,
  }).then((wish) => ({
    id: String(wish.data.id),
  }))
}

const postApiVisits = (id) => {
  return fetchApi({
    query: `/v3/visits`,
    method: `POST`,
    body: {
      data: {
        type: `visits`,
        attributes: {},
        relationships: {
          site: {
            data: {
              type: `sites`,
              id: id,
            },
          },
        },
      },
    },
    userToken: true,
  }).then((visit) => ({
    id: String(visit.data.id),
  }))
}

const postApiLists = ({ name, hidden, siteId }) => {
  return fetchApi({
    query: `/v3/lists`,
    method: `POST`,
    body: {
      data: {
        type: `lists`,
        attributes: {
          name: name,
          hidden: hidden,
        },
        relationships: {
          sites: {
            data: [
              {
                type: `sites`,
                id: siteId,
              },
            ],
          },
        },
      },
    },
    userToken: true,
  }).then((list) => ({
    id: String(list.data.id),
  }))
}

const postApiListListings = ({ id, siteId }) => {
  return fetchApi({
    query: `/v3/lists/${id}/relationships/listings`,
    method: `POST`,
    body: {
      data: [
        {
          type: `listings`,
          attributes: {},
          relationships: {
            site: {
              data: {
                type: `sites`,
                id: siteId,
              },
            },
          },
        },
      ],
    },
    userToken: true,
  })
}

const postApiTripItems = ({ id, siteId }) => {
  return fetchApi({
    query: `/v3.2/trips/${id}/relationships/items`,
    method: `POST`,
    body: {
      data: [
        {
          type: `trip_items`,
          attributes: {},
          relationships: {
            site: {
              data: {
                type: `sites`,
                id: siteId,
              },
            },
          },
        },
      ],
    },
    userToken: true,
  })
}

const deleteApiWatches = (id) => {
  return fetchApi({
    query: `/v3/watches/${id}`,
    method: `DELETE`,
    userToken: true,
    jsonResponse: false,
  })
}

const deleteApiWishes = (id) => {
  return fetchApi({
    query: `/v3/wishes/${id}`,
    method: `DELETE`,
    userToken: true,
    jsonResponse: false,
  })
}

const deleteApiVisits = (id) => {
  return fetchApi({
    query: `/v3/visits/${id}`,
    method: `DELETE`,
    userToken: true,
    jsonResponse: false,
  })
}

const deleteApiListListings = ({ id, siteId }) => {
  return fetchApi({
    query: `/v3/lists/${id}/relationships/listings`,
    method: `DELETE`,
    body: {
      data: [
        {
          type: `listings`,
          attributes: {},
          relationships: {
            site: {
              data: {
                type: `sites`,
                id: siteId,
              },
            },
          },
        },
      ],
    },
    userToken: true,
  })
}

const deleteApiTripItems = ({ id, siteId }) => {
  return fetchApi({
    query: `/v3.2/trips/${id}/relationships/items`,
    method: `DELETE`,
    body: {
      data: [
        {
          type: `trip_items`,
          attributes: {},
          relationships: {
            site: {
              data: {
                type: `sites`,
                id: siteId,
              },
            },
          },
        },
      ],
    },
    userToken: true,
  })
}

// const fetchApiSearchBoundary = (params) => {
//   return fetchApi({
//     query: `/v3/search/boundary`,
//     method: `POST`,
//     body: params,
//   }).then((items) => items)
// }

export {
  fetchApiCategories,
  fetchApiGuides,
  fetchApiOrganization,
  fetchApiOrganizations,
  fetchApiMunicipalities,
  fetchApiCounties,
  fetchApiSiteInfo,
  fetchApiSiteShapes,
  fetchApiSiteElevations,
  fetchApiSiteNeighbours,
  fetchApiUser,
  fetchApiUserLists,
  fetchApiUserTrips,
  fetchApiUserVisits,
  fetchApiUserWishes,
  fetchApiUserWatches,
  fetchApiTrip,
  fetchApiList,
  fetchApiTrafficStops,
  fetchApiTrafficStop,
  fetchApiTrafficTrips,
  postApiWatches,
  postApiWishes,
  postApiVisits,
  postApiLists,
  postApiListListings,
  postApiTripItems,
  deleteApiWatches,
  deleteApiWishes,
  deleteApiVisits,
  deleteApiListListings,
  deleteApiTripItems,
  // fetchApiSearchBoundary,
}
